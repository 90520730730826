import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../routes/paths';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import { useSnackbar } from '../../components/snackbar';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { resendVerifyEmail } from '../../api/user';
import VerifyEmailPopup from '../../pages/components/VerifyEmailPopup';

// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const { login, isError, setIsError, isWarning, setIsWarning } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [email, setEmail] = useState('');
  const [verificationMessage, setVerificationMessage] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsError();
    setIsWarning();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    setIsLoading(true);
    setEmail(data.email);
    await login(data.email, data.password).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (isWarning?.verified === false || isWarning?.verified === 'false') {
      setOpenVerify(true);
    }
  }, [isWarning]);

  const handleSendVerifyEmail = () => {
    setIsLoading(true);
    resendVerifyEmail(email)
      .then((res) => {
        if (res?.status === 'success') {
          setVerificationMessage(res?.message);
        }
        if (res?.status === 'success' && verificationMessage) {
          enqueueSnackbar(res?.message);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error?.message, { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseVerify = () => {
    setOpenVerify(false);
    setVerificationMessage('');
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {isError && (
            <Alert severity="error">
              {isError?.message || 'Something went wrong! please try again'}
            </Alert>
          )}

          <RHFTextField name="email" label="Email address" />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack alignItems="flex-end" sx={{ my: 2 }}>
          <Link
            component={RouterLink}
            to={PATH_AUTH.resetPassword}
            variant="body2"
            color="inherit"
            underline="always"
          >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
        >
          Login
        </LoadingButton>
      </FormProvider>
      <VerifyEmailPopup
        open={openVerify}
        onClose={handleCloseVerify}
        onSubmit={handleSendVerifyEmail}
        isLoading={isLoading}
        verificationMessage={verificationMessage}
      />
    </>
  );
}
