import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useMemo, useEffect } from 'react';
import { getValidUser, userEmailLogin, userRegister } from '../api/user';

// utils
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  isLoading: true,
  user: null,
  isError: null,
  isWarning: null,
  isRegistered: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      isLoading: false,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: true,
      user: action.payload.user,
      isLoading: false,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isRegistered: action.payload.isRegistered,
      isLoading: false,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: false,
      user: null,
      isLoading: false,
    };
  }
  if (action.type === 'ERROR') {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: false,
      isError: action.payload.isError,
      isLoading: false,
    };
  }
  if (action.type === 'WARNING') {
    return {
      ...state,
      isWarning: action.payload.isWarning,
      isLoading: false,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const access_token = storageAvailable
        ? localStorage.getItem('thirdai_model_bazaar_access_token')
        : '';

      if (access_token && isValidToken(access_token)) {
        setSession(access_token);

        getValidUser()
          .then((response) => {
            if (response.status === 'success') {
              const user = response.data;
              dispatch({
                type: 'INITIAL',
                payload: {
                  isAuthenticated: true,
                  user,
                },
              });
            } else {
              setSession(null);
              dispatch({
                type: 'LOGOUT',
              });
            }
          })
          .catch(() => {
            setSession(null);
            dispatch({
              type: 'LOGOUT',
            });
          });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        try {
          userEmailLogin(email, password)
            .then((response) => {
              if (response?.status === 'success' && response?.data?.verified) {
                const { access_token, user } = response.data;
                setSession(access_token);
                dispatch({
                  type: 'LOGIN',
                  payload: {
                    access_token,
                  },
                });
                dispatch({
                  type: 'INITIAL',
                  payload: {
                    isAuthenticated: true,
                    user,
                  },
                });
                resolve();
              } else if (response?.data?.verified === false) {
                dispatch({
                  type: 'WARNING',
                  payload: {
                    isWarning: response.data,
                  },
                });
                reject();
              }
            })
            .catch((error) => {
              dispatch({
                type: 'ERROR',
                payload: {
                  isError: error,
                },
              });
              reject();
            });
        } catch (err) {
          dispatch({
            type: 'ERROR',
            payload: {
              isError: err,
            },
          });
          reject();
        }
      }),
    []
  );

  // LOGIN WITH GOOGLE
  const loginWithGoogle = useCallback(async (access_token) => {
    console.log(access_token, 'login with google');
  }, []);

  // LOGIN WITH MICROSOFT
  const loginWithMicrosoft = useCallback(() => {
    console.log('Login With Microsoft');
  }, []);

  // REGISTER
  const register = useCallback(
    (email, password, username) =>
      new Promise((resolve, reject) => {
        try {
          userRegister(email, password, username)
            .then((response) => {
              if (response?.status === 'success') {
                dispatch({
                  type: 'REGISTER',
                  payload: {
                    isRegistered: true,
                  },
                });
                resolve();
              } else {
                reject();
              }
            })
            .catch((error) => {
              dispatch({
                type: 'ERROR',
                payload: {
                  isError: error,
                },
              });
              reject();
            });
        } catch (err) {
          dispatch({
            type: 'ERROR',
            payload: {
              isError: err,
            },
          });
          reject();
        }
      }),
    []
  );

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // SETISERROR
  const setIsError = useCallback(() => {
    dispatch({
      type: 'ERROR',
      payload: {
        isError: null,
      },
    });
  }, []);

  // SETISWARNING
  const setIsWarning = useCallback(() => {
    dispatch({
      type: 'WARNING',
      payload: {
        isWarning: null,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      isError: state.isError,
      isWarning: state.isWarning,
      isLoading: state.isLoading,
      isRegistered: state.isRegistered,
      method: 'jwt',
      initialize,
      login,
      loginWithGoogle,
      loginWithMicrosoft,
      register,
      logoutUser: logout,
      setIsError,
      setIsWarning,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.isError,
      state.isWarning,
      state.isLoading,
      state.isRegistered,
      initialize,
      login,
      loginWithGoogle,
      loginWithMicrosoft,
      logout,
      register,
      setIsError,
      setIsWarning,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
