import axios from 'axios';
// config
import { API_URL } from '../config-global';

// ----------------------------------------------------------------------

const sessionExpiredModal = new Event('sessionExpired');

const axiosInstance = axios.create();
if (API_URL) axiosInstance.defaults.baseURL = API_URL;
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      document.dispatchEvent(sessionExpiredModal);
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
