import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  allExperimentDetails: {},
  targetColumnName: '',
  strongLearnData: [],
  weakLearnData: [],
  experimentState: '',
};

const slice = createSlice({
  name: 'experimentDetails',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addExperimentDetails(state, action) {
      state.allExperimentDetails = action.payload;
    },

    addTargetColumnName(state, action) {
      state.targetColumnName = action.payload;
    },

    addExperimentState(state, action) {
      state.experimentState = action.payload;
    },

    addStrongLearnData(state, action) {
      state.strongLearnData = action.payload;
    },

    addWeakLearnData(state, action) {
      state.weakLearnData = action.payload;
    },

    getExperimentDetails(state) {
      const data = state.experimentDetails;
      return data;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addExperimentDetails,
  getExperimentDetails,
  addTargetColumnName,
  addExperimentState,
  addStrongLearnData,
  addWeakLearnData,
} = slice.actions;
