import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
// form
import { useTheme } from '@mui/material/styles';
//
// @mui
import {
  Stack,
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import Iconify from '../../../components/iconify';
import { UploadAvatar } from '../../../components/upload';
import defaultProfile from '../../../assets/man-avatar-icon.png';
import UpdateProfile from './UpdateProfile';

// ----------------------------------------------------------------------

ViewProfile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function ViewProfile({ open, onClose, ...other }) {
  const { user } = useAuthContext();
  const theme = useTheme();

  const [value, setValue] = useState(defaultProfile);
  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    const newFile = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    if (file) {
      setValue(newFile);
    }
  }, []);

  // Open Update Profile
  const [openUpdateProfile, setOpenUpdateProfile] = useState(false);
  const handleOpenUpdateProfile = () => {
    onClose();
    setOpenUpdateProfile(true);
  };
  const handleCloseUpdateProfile = () => {
    setOpenUpdateProfile(false);
  };

  return (
    <>
      <Dialog className="fullHeightDialog" maxWidth="sm" open={open} onClose={onClose} {...other}>
        <DialogTitle
          sx={{
            p: theme.spacing(2, 3, 1, 3),
            borderBottom: `1px solid ${theme.palette.primary.lighter}`,
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            Profile
            <IconButton color="primary" onClick={onClose}>
              <Iconify icon="ic:round-close" />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2} sx={{ p: 2 }}>
            <UploadAvatar
              accept={{
                'image/*': [],
              }}
              error=""
              file={value}
              onDrop={handleDrop}
            />
          </Stack>
          <Stack spacing={2} sx={{ textAlign: 'center', my: 1 }}>
            <Typography variant="h5" color="text.secondary">
              {user?.displayName}
            </Typography>
          </Stack>
          <Stack sx={{ mt: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              Profile Details
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ my: 1.5 }}>
            <Iconify icon="mdi:user-circle" color="text.secondary" />
            <Typography variant="body2">{user?.username}</Typography>
          </Stack>
          {/* <Stack direction="row" alignItems="center" spacing={1} sx={{ my: 1.5 }}>
            <Iconify icon="fa:suitcase" color="text.secondary" />
            <Typography variant="body2">AI Engineer</Typography>
          </Stack> */}
          <Stack direction="row" alignItems="center" spacing={1} sx={{ my: 1.5 }}>
            <Iconify icon="ic:round-email" color="text.secondary" />
            <Typography variant="body2">{user?.email}</Typography>
          </Stack>
          {/* <Stack direction="row" alignItems="center" spacing={1} sx={{ my: 1.5 }}>
            <Iconify icon="ic:baseline-phone-android" color="text.secondary" />
            <Typography variant="body2">+91-9754114540</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ my: 1.5 }}>
            <Iconify icon="ic:baseline-location-on" color="text.secondary" />
            <Typography variant="body2">India</Typography>
          </Stack> */}
        </DialogContent>

        {/* <DialogActions sx={{ justifyContent: 'center' }}>
          <Stack direction="row" alignItems="center" spacing={3} justifyContent="center">
            <Button variant="contained" sx={{ minWidth: 250 }} onClick={handleOpenUpdateProfile}>
              Edit
            </Button>
          </Stack>
        </DialogActions> */}
      </Dialog>
      {openUpdateProfile && (
        <UpdateProfile open={openUpdateProfile} onClose={handleCloseUpdateProfile} />
      )}
    </>
  );
}
