import { Outlet } from 'react-router-dom';
// @mui
import { Box, Container } from '@mui/material';
//
import { useEffect } from 'react';
import Header from './Header';

// ----------------------------------------------------------------------

export default function MainLayout() {
  useEffect(() => {
    const deployment_redirect_endpoint = window.localStorage.getItem(
      'thirdai_deployment_auth_redirect'
    );
    if (!deployment_redirect_endpoint) {
      return;
    }
    const baseUrl = `${window.location.protocol}//${window.location.host}/`;
    const newUrl = `${baseUrl}search?user-model=${deployment_redirect_endpoint}`;
    window.localStorage.setItem('thirdai_deployment_auth_redirect', null);
    window.open(newUrl);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />
      <Container maxWidth="xl" sx={{ display: 'flex', gap: 4 }}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: { xs: 7, md: 10 },
            minHeight: '60vh',
          }}
        >
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
}
