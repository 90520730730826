import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, Link, Button } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import AuthWithSocial from './AuthWithSocial';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Sign in to ThirdAI</Typography>

        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2" sx={{ pt: 0.2 }}>
            Create an account
          </Link>
        </Stack>
      </Stack>

      <AuthLoginForm />

      {/* <AuthWithSocial /> */}

      <Stack alignItems="center" justifyContent="center">
        <Button
          component={RouterLink}
          color="secondary"
          to="/model"
          sx={{ maxWidth: 'max-content', minWidth: '200px' }}
        >
          Skip Login
        </Button>
      </Stack>
    </LoginLayout>
  );
}
