import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function secondsToMS(seconds) {
  // day, h, m and s
  const days = Math.floor(seconds / (24 * 60 * 60));
  seconds -= days * (24 * 60 * 60);
  const hours = Math.floor(seconds / (60 * 60));
  seconds -= hours * (60 * 60);
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  const sec = Math.floor(seconds);
  seconds -= sec;
  const ms = seconds * 1000;
  return `${days > 0 ? `${days}day,` : ''} ${hours > 0 ? `${hours}h,` : ''} ${
    minutes > 0 ? `${minutes}m,` : ''
  } ${sec > 0 ? `${sec}s,` : ''} ${ms.toFixed(3)}ms `;
}
export function secondsToSecond(seconds) {
  // day, h, m and s
  const days = Math.floor(seconds / (24 * 60 * 60));
  seconds -= days * (24 * 60 * 60);
  const hours = Math.floor(seconds / (60 * 60));
  seconds -= hours * (60 * 60);
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  const sec = Math.floor(seconds);
  seconds -= sec;
  return `${days > 0 ? `${days}day,` : ''} ${hours > 0 ? `${hours}h,` : ''} ${
    minutes > 0 ? `${minutes}m,` : ''
  } ${sec > 0 ? `${sec}s` : ''}`;
}
export function secondsToMinutes(seconds) {
  // day, h, m and s
  seconds = Number(seconds);
  const days = Math.floor(seconds / (24 * 60 * 60));
  seconds -= days * (24 * 60 * 60);
  const hours = Math.floor(seconds / (60 * 60));
  seconds -= hours * (60 * 60);
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  const sec = Math.floor(seconds);
  seconds -= sec;
  const Ddisplay = days > 0 ? days + (days === 1 ? ' day, ' : ' days, ') : '';
  const Hdisplay = hours > 0 ? hours + (hours === 1 ? ' hour, ' : ' hours, ') : '';
  const Mdisplay = minutes > 0 ? minutes + (minutes === 1 ? ' minute, ' : ' minutes, ') : '';
  const Sdisplay = sec > 0 ? `${sec}sec` : `${seconds.toFixed(2)}sec`;
  return Ddisplay + Hdisplay + Mdisplay + Sdisplay;
}

export function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function byteConverter(bytes, decimals, only) {
  const K_UNIT = 1024;
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  if (bytes === 0) return '0 Byte';

  if (only === 'MB') return `${(bytes / (K_UNIT * K_UNIT)).toFixed(decimals)} MB`;

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  const resp = `${parseFloat(bytes / K_UNIT ** i).toFixed(decimals)} ${SIZES[i]}`;

  return resp;
}

export function formatReadableNumber(num, precision = 0) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}
