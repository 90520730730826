import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import globalAction from './actions';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
// ----------------------------------------------------------------------
const middleware = [sagaMiddleware];

// const store = configureStore({
//   reducer: persistReducer(rootPersistConfig, rootReducer),
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//       immutableCheck: false,
//     }),
// });
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middleware),
});

// Middleware: Redux Saga
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, globalAction, useSelector, useDispatch };
