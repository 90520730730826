import PropTypes from 'prop-types';
// @mui
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
} from '@mui/material';

// ----------------------------------------------------------------------

VerifyEmailPopup.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  verificationMessage: PropTypes.string,
};

export default function VerifyEmailPopup({
  open,
  onClose,
  onSubmit,
  isLoading,
  verificationMessage,
  ...other
}) {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} {...other}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(2, 3, 1, 3) }}>
        Your email is not verified
      </DialogTitle>

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
        {verificationMessage ? (
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle1" color="primary">
              {verificationMessage}
            </Typography>
            <Typography>Please check your email</Typography>
          </DialogContentText>
        ) : (
          <DialogContentText id="alert-dialog-description">
            Please click on Send Request for verification mail.
          </DialogContentText>
        )}
      </DialogContent>

      <DialogActions>
        {verificationMessage ? (
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading && 'true'}
            onClick={onSubmit}
            sx={{ minWidth: 100 }}
          >
            {isLoading ? 'Sending...' : 'Resend Request'}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading && 'true'}
            onClick={onSubmit}
            sx={{ minWidth: 100 }}
          >
            {isLoading ? 'Sending...' : 'Send Request'}
          </Button>
        )}

        <Button variant="outlined" color="error" onClick={onClose} sx={{ minWidth: 100 }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
