import PropTypes from 'prop-types';
// @mui
import { Typography, Stack, Container } from '@mui/material';
// components
import Image from '../../components/image';
import Logo from '../../components/logo';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  return (
    <Container maxWidth="xl">
      <StyledRoot>
        <Logo
          sx={{
            zIndex: 9,
            position: 'absolute',
            mt: { xs: 1.5, md: 5 },
            ml: { xs: 2, md: 5 },
          }}
        />
        <StyledSection>
          <Typography variant="h3" sx={{ my: 5, maxWidth: 480, textAlign: 'center' }}>
            {title || 'Hi, Welcome back'}
          </Typography>

          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src={illustration || '/assets/illustrations/illustration_dashboard.png'}
            sx={{ maxWidth: 550 }}
          />

          <StyledSectionBg />
        </StyledSection>

        <StyledContent>
          <Stack sx={{ width: 1 }}> {children} </Stack>
        </StyledContent>
      </StyledRoot>
    </Container>
  );
}
