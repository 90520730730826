import axios from '../utils/axios';

// ================= || USER API || ====================== //

export function getValidUser() {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/user/me')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function verifyUserEmail(token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/user/email-verify?verification_token=${token}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resendVerifyEmail(email) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/user/resend-verification-mail`, { email })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function userEmailLogin(email, password) {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/user/email-login', {
        headers: {
          Authorization: `Basic ${window.btoa(`${email}:${password}`)}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function userRegister(email, password, username) {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/user/email-signup-basic', {
        email,
        password,
        username,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function userResetPassword(email) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/user/reset-password?email=${email}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function userNewPassword(body) {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/user/new-password', body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateUser(values) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/user/${values?.user_id}/profile`, values)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function googleLoginCallback(search) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/user/google/callback${search}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function microsoftLoginCallback(search) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/user/ms-callback${search}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
