import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';
import { useSettingsContext } from '../settings';
// Assets
import MainLogo from '../../assets/thirdai_logo.png';
import LogoIcon from '../../assets/thirdAI_Icon.png';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const { themeLayout } = useSettingsContext();

  const isNavMini = themeLayout === 'mini';

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      src={isNavMini ? LogoIcon : MainLogo}
      sx={{ height: 40, width: 150, cursor: 'pointer', objectFit: 'contain', ...sx }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
