import PropTypes from 'prop-types';
import { useCallback, useState, memo } from 'react';

// form
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
// @mui
import {
  Stack,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import Iconify from '../../../components/iconify';
import { UploadAvatar } from '../../../components/upload';
import defaultProfile from '../../../assets/man-avatar-icon.png';
import { useSnackbar } from '../../../components/snackbar';
import { updateUser } from '../../../api/user';

// ----------------------------------------------------------------------

// const validationSchema = yup.object({});

const UpdateProfile = ({ open, onClose, ...other }) => {
  const { user, initialize } = useAuthContext();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      user_id: user?.user_id,
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      organization: user?.organization,
      country: user?.country,
    },
    // validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      updateUser(values)
        .then((res) => {
          if (res.status === 'success') {
            setIsLoading(false);
            enqueueSnackbar(res?.message, { variant: 'success' });
            initialize();
          } else {
            setIsLoading(false);
            enqueueSnackbar(res?.message, { variant: 'error' });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          enqueueSnackbar(error?.message, { variant: 'error' });
        });
    },
  });

  const [value, setValue] = useState(defaultProfile);
  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    const newFile = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    if (file) {
      setValue(newFile);
    }
  }, []);

  return (
    <Dialog className="fullHeightDialog" maxWidth="xs" open={open} onClose={onClose} {...other}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle
          sx={{
            p: theme.spacing(2, 3, 1, 3),
            borderBottom: `1px solid ${theme.palette.primary.lighter}`,
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            Update Profile
            <IconButton color="primary" onClick={onClose}>
              <Iconify icon="ic:round-close" />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2} sx={{ p: 2 }}>
            <UploadAvatar
              accept={{
                'image/*': [],
              }}
              error=""
              file={value}
              onDrop={handleDrop}
            />
          </Stack>
          <Grid container spacing={2} sx={{ py: 2 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="first_name"
                name="first_name"
                label="First Name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                helperText={formik.touched.first_name && formik.errors.first_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="last_name"
                name="last_name"
                label="Last Name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                disabled
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="organization"
                name="organization"
                label="Organization"
                value={formik.values.organization}
                defaultValue="Third AI"
                onChange={formik.handleChange}
                error={formik.touched.organization && Boolean(formik.errors.organization)}
                helperText={formik.touched.organization && formik.errors.organization}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="userRole"
                name="userRole"
                label="Role"
                select
                value={formik.values.userRole}
                defaultValue="AiEngineer"
                onChange={formik.handleChange}
                error={formik.touched.userRole && Boolean(formik.errors.userRole)}
                helperText={formik.touched.userRole && formik.errors.userRole}
              >
                <MenuItem value="AiEngineer">AI Engineer</MenuItem>
                <MenuItem value="developer">Developer</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="country"
                name="country"
                label="Country"
                select
                value={formik.values.country}
                defaultValue="india"
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              >
                <MenuItem value="india">India</MenuItem>
                <MenuItem value="usa">USA</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="mobile"
                name="mobile"
                label="Mobile Number (optional)"
                value={formik.values.mobile}
                type="number"
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Stack direction="row" alignItems="center" spacing={3} justifyContent="center">
            <LoadingButton
              variant="contained"
              type="submit"
              sx={{ minWidth: 250 }}
              loading={isLoading}
            >
              Update Profile
            </LoadingButton>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};
UpdateProfile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default memo(UpdateProfile);
