// config
import { PATH_AFTER_LOGIN } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  // {
  //   title: 'Home',
  //   icon: <Iconify icon="eva:home-fill" />,
  //   path: '/model',
  // },
];

export default navConfig;
