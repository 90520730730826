import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  allColumnTypes: [],
};

const slice = createSlice({
  name: 'columnTypes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addColumnTypes(state, action) {
      state.allColumnTypes = action.payload;
    },

    getColumnTypes(state) {
      const data = state.columnTypes;
      return data;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addColumnTypes, getColumnTypes } = slice.actions;
