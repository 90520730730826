/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */
import { takeEvery, put, select } from 'redux-saga/effects';
import { closeSnackbar, openSnackbar } from '../slices/snackbar';
import globalAction from '../actions';

function* showToaster({ payload }) {
  yield put(
    openSnackbar({
      open: true,
      message: payload.message,
      variant: 'alert',
      alert: {
        color: payload.status,
      },
      actionButton: false,
    })
  );

  setTimeout(() => {
    put(
      closeSnackbar({
        open: false,
      })
    );
  }, 3000);
}

function* changeListingOrder({ payload }) {
  let currentOrder = yield select((state) => state.listing.order);
  currentOrder = currentOrder === 'desc' ? 'asc' : 'desc';
  yield put(globalAction('SET_ORDER', { order: currentOrder, orderBy: payload.orderBy }));
}

// function* getCustomer({payload}){

// }

export default function* watchAppSaga() {
  yield takeEvery('SHOW_TOASTER', showToaster);
  yield takeEvery('CHANGE_ORDER', changeListingOrder);
}
