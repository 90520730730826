import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import MainLayout from '../layouts/main/MainLayout';
// import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  VerifyEmailPage,
  NewPasswordPage,
  ResetPasswordPage,
  ModelPage,
  PublicModel,
  PrivateModel,
  ProtectedModel,
  RequestListPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      // element: <MainLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
            { path: 'user/redirect-verify', element: <VerifyEmailPage /> },
          ],
        },
      ],
    },

    // Main Layout
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: (
            <AuthGuard>
              <ModelPage />
            </AuthGuard>
          ),
        },
        {
          path: '/request-list',
          element: (
            <AuthGuard>
              <RequestListPage />
            </AuthGuard>
          ),
        },
        { path: '/model', element: <ModelPage /> },
        { path: '/model/public', element: <PublicModel /> },
        { path: '/model/private', element: <PrivateModel /> },
        { path: '/model/protected', element: <ProtectedModel /> },
        { path: '/google-login', element: <ModelPage /> },
        { path: '/microsoft-login', element: <ModelPage /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // Dashboard
    // {
    //   path: '/',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { path: '', element: <HomeAppPage /> },
    //     { path: 'home', element: <HomeAppPage /> },

    //     { path: '*', element: <Navigate to="/404" replace /> },
    //   ],
    // },
  ]);
}
