import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  allFiles: null,
};

const slice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addFiles(state, action) {
      state.allFiles = action.payload;
    },

    getFiles(state) {
      const data = state.files;
      return data;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addFiles, getFiles } = slice.actions;
