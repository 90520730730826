import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const VerifyEmailPage = Loadable(lazy(() => import('../pages/auth/VerifyEmailPage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: MAIN
export const HomeAppPage = Loadable(lazy(() => import('../pages/home/Home')));

// PUBLIC
export const ModelPage = Loadable(lazy(() => import('../pages/model/Model')));
export const PublicModel = Loadable(lazy(() => import('../pages/model/PublicModel')));
export const PrivateModel = Loadable(lazy(() => import('../pages/model/PrivateModel')));
export const ProtectedModel = Loadable(lazy(() => import('../pages/model/ProtectedModel')));
export const RequestListPage = Loadable(lazy(() => import('../pages/requests/RequestList')));
